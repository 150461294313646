<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>                        
                        <span class="text-h5">Registar user</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">      
                    <v-form class="mb-16" @submit.prevent="addUser">
                        <fieldset :disabled="loading">                        
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                    v-model="user.name"
                                    :error-messages="errors"
                                    label="Nome"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" vid="email" name="email" rules="required">
                                    <v-text-field
                                    v-model="user.email"
                                    :error-messages="errors"
                                    label="Email"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" vid="username" name="username" rules="required">
                                    <v-text-field
                                    v-model="user.username"
                                    :error-messages="errors"
                                    label="Username"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>                            
                            <v-col cols="12" md="6">
                                    <v-text-field
                                    v-model="user.phone_number"
                                    label="Telefone"
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch label="Ativo" v-model="user.is_active" inset></v-switch>
                            </v-col>
                        </v-row>             
                        <v-row>
                            <v-col cols="12">
                                    <v-text-field
                                    v-model="user.password"
                                    v-if="method == 'create'"
                                    label="Password"
                                    type="password"
                                    ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="user.password"
                                    label="Password"
                                    type="password"
                                ></v-text-field>
                            </v-col>
                        </v-row>                 
                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                            </v-row>
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ErrorSnackbar v-model="error.show" :message="error.message" style="white-space: pre-line;"/>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            User gravado com sucesso
        </DialogSuccess>
    </v-row>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ClientUsers from "@/api/ClientUsers.js"
import TenantRole from "@/api/TenantRole.js"
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
    components:{
        DialogSuccess,
        ErrorSnackbar
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'create'
        },
        selectedUser:{
            default: null
        }
    },
    mounted(){
        TenantRole.list(null, this.tenant).then(response => {
            this.roles = response.data
        })
    },
    data(){
        return{     
            error: {
                title: '',
                message: '',
                show:false,
                showExtensive: false
            }, 
            roles: null,
            dialog: false,
            loading: false,         
            success: false, 
            user:{
                name:'',
                phone_number: '',
                email: '',
                username: '',
                is_active: 0,
                password: '',
                id: '',
                roles: null
            }
        }
    },
    watch:{
        selectedUser:{
            immediate: true,
            handler(val){
                if(val == null)
                    return
                    
                this.user.name = val.name
                this.user.phone_number = val.phone_number
                this.user.email = val.email
                this.user.username = val.username
                this.user.is_active = val.is_active
                this.user.id = val.id
        
          
            }
        }
    },
    methods:{
        resetField(){
            this.user = {
                name:'',
                phone_number: '',
                email: '',
                username: '',
                is_active: 0,
                password: '',
                id: '',
                roles: null
            }
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadUsers')
        },
        openDialog(){
            this.dialog = true
        },
        addUser(){
            if(this.user.password != '')
                this.user.password = this.$encryptValue(this.user.password)
            
            this.roles.forEach(element => {
                if(element.name == 'super')
                    this.user.roles = [element.id]
            });
            this.user.place = 'c4'  
            ClientUsers[this.method](this.user, this.tenant).then(() => {
        
            this.success = true;

            this.loading = false;

            this.resetField()

            }).catch(err => {
            this.loading = false;


            if(err.response.status == 422) {
                let problems = 'Erros vindos do Servidor: \n\n'
                if(err.response.data.errors.email != undefined)
                    problems = problems + err.response.data.errors.email + "\n\n"

                if(err.response.data.errors.name != undefined)
                    problems = problems + err.response.data.errors.name + "\n\n"

                if(err.response.data.errors.username != undefined)
                    problems = problems + err.response.data.errors.username + "\n\n"

                this.error.message = problems
                this.error.show = true
                

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
            }
            

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            });
            this.user.password = ''
        },
    }
}
</script>