import BaseApi from "./BaseApi";

export default class Notification extends BaseApi{
    construct() {}

    static async list(unread, offset = 0) {
        let params = {
            unread: unread,
            offset: offset
        }
        return await Notification.getAxiosInstance().get(Notification.getApiUrl(),{params: params});
    }

    static async read() {
        const { data: response } = await Notification.getAxiosInstance().post(Notification.getApiUrl());

        return response;
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/c4/notifications/";
    }

}