var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.label != '')?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"sticky-toolbar mb-4",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[(_vm.method=='create')?_c('span',{staticClass:"text-h5"},[_vm._v("Registar fatura")]):_c('span',{staticClass:"text-h5"},[_vm._v("Editar fatura")])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"form_invoice",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.addInvoice.apply(null, arguments)}}},[_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.method=='create')?_c('validation-provider',{attrs:{"vid":"file","name":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"accept":".pdf","label":"Fatura","error-messages":errors},model:{value:(_vm.invoice.file),callback:function ($$v) {_vm.$set(_vm.invoice, "file", $$v)},expression:"invoice.file"}})]}}],null,true)}):_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":_vm.downloadInvoice}},[_vm._v(" Download fatura "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-download-box ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Paga"},model:{value:(_vm.invoice.paid),callback:function ($$v) {_vm.$set(_vm.invoice, "paid", $$v)},expression:"invoice.paid"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"title","name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Titulo"},model:{value:(_vm.invoice.title),callback:function ($$v) {_vm.$set(_vm.invoice, "title", $$v)},expression:"invoice.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"input-7-1","error-messages":errors,"label":"Descriçao"},model:{value:(_vm.invoice.description),callback:function ($$v) {_vm.$set(_vm.invoice, "description", $$v)},expression:"invoice.description"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"total","name":"total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Total","prepend-icon":"mdi-currency-eur"},model:{value:(_vm.invoice.total),callback:function ($$v) {_vm.$set(_vm.invoice, "total", $$v)},expression:"invoice.total"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"expires_at","name":"expires_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"error-messages":errors,"label":"Expira a","date":_vm.invoice.expires_at,"return-object":""},on:{"changed":_vm.expireChanged}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1)])]}}])})],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Fatura gravada com sucesso ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }