var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-10"},[_c('v-card-text',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Subscrição","name":"Subscrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Subscrição *","clearable":"","error-messages":errors},model:{value:(_vm.fields.type),callback:function ($$v) {_vm.$set(_vm.fields, "type", $$v)},expression:"fields.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Preço","name":"Preço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","prepend-inner-icon":"mdi-currency-eur","label":"Preço *","clearable":"","error-messages":errors},model:{value:(_vm.fields.price),callback:function ($$v) {_vm.$set(_vm.fields, "price", $$v)},expression:"fields.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Dias","name":"Dias","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","prepend-inner-icon":"mdi-calendar-alert","label":"Dias","clearable":"","error-messages":errors},model:{value:(_vm.fields.days),callback:function ($$v) {_vm.$set(_vm.fields, "days", $$v)},expression:"fields.days"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Subscrição gravada com sucesso! ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }