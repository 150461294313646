<template>
    <section class="mt-16">
        <Synchronization />
    </section>
</template>
<script>
import Synchronization from '@/components/synchronization/Synchronization.vue'

export default{
    components:{
        Synchronization
    }
}
</script>