var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Subscrições expiradas")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"hide-default-footer":true,"locale":"pt-pt"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('small',[_vm._v(_vm._s(item.name))])])]}},{key:"item.days_left",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('small',[_vm._v(_vm._s(item.days_left))])])]}},{key:"item.subscription_end",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('small',[_vm._v(_vm._s(item.subscription_end))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('small',[_vm._v(_vm._s(item.type))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewTenant(item)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }