var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('v-data-table',{staticClass:"mt-16 elevation-10",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('small',[_vm._v(_vm._s(item.name))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('small',[_vm._v(_vm._s(item.email))])])]}},{key:"item.last_seen",fn:function(ref){
var item = ref.item;
return [(item.last_seen != null)?_c('p',[_c('small',[_vm._v(_vm._s(_vm.checkLastSeen(item.last_seen)))])]):_c('p',[_c('small',[_vm._v("(Nunca iniciou sessão)")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.hourPassed(item.last_seen))?_c('status-indicator',{attrs:{"status":_vm.passedMoreTwoHours(item.last_seen) ? 'negative' : 'intermediary'}}):_c('status-indicator',{attrs:{"status":item.active_session ? 'positive' : 'negative'}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }