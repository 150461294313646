<template>
    <v-container fluid v-if="!loading">
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="createRegistry" >            
                <v-card class="elevation-0">
                    <v-row justify="end">
                        <v-col cols="12" md="4">
                            <v-btn @click="populate" class="error" rounded block>
                                Popular dados
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">                    
                            <validation-provider v-slot="{ errors }" vid="Server URL" name="Server URL" rules="required">
                                <v-text-field
                                    v-model="fields.server_url"
                                    dense
                                    :error-messages="errors"
                                    label="Server URL *"
                                />
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="fields.sql_server"
                                dense
                                label="SQL Server *"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="fields.system_db"
                                dense
                                label="System DB *"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="fields.erp_login"
                                dense
                                label="ERP Login *"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="fields.erp_password"
                                dense
                                label="ERP Password *"
                            />
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn :disabled="invalid" class="success" type="submit" rounded>Inserir</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </validation-observer>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Dados do eticadata gravados com sucesso! Vamos fazer reload de toda a página para o Tenant recarregar toda a sua informação
        </DialogSuccess>

        <DialogSuccess :opened="successPopulate" @on-ok="onSuccessPopulate" >
            Serviços correram e dados estão preenchidos com o Eticadata
        </DialogSuccess>

    </v-container>
</template>
<script>
import TenantEticadata from '@/api/TenantEticadata.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
    name:"Eticadata",
    components:{
        DialogSuccess
    },
    props: ['tenant', 'data'],
    data: () => ({
            loading: true,
            hasData: false,
            fields: {
                tenant_id: null,
                server_url: null,
                sql_server: null,
                system_db: null,
                erp_login: null,
                erp_password: null
            },
            error:{
                title: null,
                message: null
            },
            success: false,
            successPopulate: false
    }),
    mounted(){
        if(this.data != null){
            this.fields = this.data
            this.hasData = true
        }

        this.loading = false
    },
    methods:{
        onSuccessPopulate(){
            this.successPopulate = false
        },
        onSuccess(){
            this.success = false
            window.location.reload();
        },
        async populate(){
            await TenantEticadata.populate(this.fields).then(() => {
    
                this.successPopulate = true;

                this.loading = false;
                
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        createRegistry(){
            this.loading = true;

            this.fields.tenant_id = this.tenant

            TenantEticadata.create(this.fields).then(() => {
    
                this.success = true;

                this.loading = false;
                
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>