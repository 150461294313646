<template>
    <v-card class="elevation-10">
      <v-card-text>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" vid="titulo" name="Título" rules="required">
                    <v-text-field
                      v-model="fields.title"
                      prepend-inner-icon="mdi-format-title"
                      label="Título *"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                    <v-text-field
                      v-model="fields.name"
                      prepend-inner-icon="mdi-rename"
                      label="Nome *"
                      hint="Este é o nome identificado pelo sistema"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" vid="descrição" name="Descrição" rules="required">
                    <v-textarea
                      prepend-inner-icon="mdi-text-box"
                      name="input-7-1"
                      label="Descrição"
                      v-model="fields.description"
                      hint="Escreva uma breve descrição sobre a função desta feature"
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                  Gravar
                </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Feature gravada com sucesso!
          </DialogSuccess>
        </validation-observer>
      </v-card-text>
    </v-card>
  </template>
  <script>
  import Feature from "@/api/Feature.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components: {
      DialogSuccess
    },
    props: {
     feature: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
      feature: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      fields: {
        id: null,
        name: null,
        display_name:null,
        users:[],
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      users: [],
      success: false,
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/internal_management/features');
      },
      fillBaseData(){
        
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
        
  
          Feature[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  