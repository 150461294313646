var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!this.loading)?_c('v-card',[_c('v-toolbar',{staticClass:"sticky-toolbar mb-4",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Subscrição")])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"form_invoice",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.addSubscription.apply(null, arguments)}}},[_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"Inicio","name":"Inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Início","prepend-icon":"mdi-calendar","error-messages":errors,"hint":"Escolha uma data para desbloquear os restantes campos","persistent-hint":"","readonly":""},model:{value:(_vm.fields.subscription_start),callback:function ($$v) {_vm.$set(_vm.fields, "subscription_start", $$v)},expression:"fields.subscription_start"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.fields.subscription_start),callback:function ($$v) {_vm.$set(_vm.fields, "subscription_start", $$v)},expression:"fields.subscription_start"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Tipo","name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.subscription_types,"error-messages":errors,"label":"Subscrição","disabled":_vm.fields.subscription_start == null,"item-text":"type","item-value":"id"},on:{"change":_vm.changedSubscription},model:{value:(_vm.fields.subscription_id),callback:function ($$v) {_vm.$set(_vm.fields, "subscription_id", $$v)},expression:"fields.subscription_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"Fim","name":"Fim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fim","disabled":_vm.fields.subscription_id == null,"prepend-icon":"mdi-calendar","error-messages":errors,"readonly":""},model:{value:(_vm.fields.subscription_end),callback:function ($$v) {_vm.$set(_vm.fields, "subscription_end", $$v)},expression:"fields.subscription_end"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fields.subscription_end),callback:function ($$v) {_vm.$set(_vm.fields, "subscription_end", $$v)},expression:"fields.subscription_end"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"typology","name":"Tipologia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"hint":"Escolha o tipo da subscrição","items":['Gold', 'Diamond', 'Tailored', 'Trial'],"error-messages":errors,"label":"Tipo","persistent-hint":"","disabled":_vm.fields.subscription_id == null,"return-object":"","single-line":""},model:{value:(_vm.fields.subscription_typology),callback:function ($$v) {_vm.$set(_vm.fields, "subscription_typology", $$v)},expression:"fields.subscription_typology"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Preço","name":"Preço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"disabled":_vm.fields.subscription_id == null,"label":"Preço","prepend-icon":"mdi-currency-eur"},model:{value:(_vm.fields.price),callback:function ($$v) {_vm.$set(_vm.fields, "price", $$v)},expression:"fields.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Paga"},model:{value:(_vm.fields.paid),callback:function ($$v) {_vm.$set(_vm.fields, "paid", $$v)},expression:"fields.paid"}})],1),(_vm.fields.paid)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"paga_a","name":"Paga_a","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Paga em","prepend-icon":"mdi-calendar","error-messages":errors,"readonly":""},model:{value:(_vm.fields.paid_at),callback:function ($$v) {_vm.$set(_vm.fields, "paid_at", $$v)},expression:"fields.paid_at"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.fields.paid_at),callback:function ($$v) {_vm.$set(_vm.fields, "paid_at", $$v)},expression:"fields.paid_at"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"8"}}),(!_vm.hasInvoice)?_c('div',{attrs:{"id":"invoicesDIV"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Associar fatura"},model:{value:(_vm.fields.addInvoice),callback:function ($$v) {_vm.$set(_vm.fields, "addInvoice", $$v)},expression:"fields.addInvoice"}})],1),(_vm.fields.addInvoice)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"accept":".pdf","label":"Fatura"},model:{value:(_vm.fields.invoice.file),callback:function ($$v) {_vm.$set(_vm.fields.invoice, "file", $$v)},expression:"fields.invoice.file"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"title","name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Titulo"},model:{value:(_vm.fields.invoice.title),callback:function ($$v) {_vm.$set(_vm.fields.invoice, "title", $$v)},expression:"fields.invoice.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"input-7-1","error-messages":errors,"label":"Descriçao"},model:{value:(_vm.fields.invoice.description),callback:function ($$v) {_vm.$set(_vm.fields.invoice, "description", $$v)},expression:"fields.invoice.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"total","name":"total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Total","prepend-icon":"mdi-currency-eur"},model:{value:(_vm.fields.invoice.total),callback:function ($$v) {_vm.$set(_vm.fields.invoice, "total", $$v)},expression:"fields.invoice.total"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Expira","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.invoice.expires),callback:function ($$v) {_vm.$set(_vm.fields.invoice, "expires", $$v)},expression:"fields.invoice.expires"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.fields.invoice.expires),callback:function ($$v) {_vm.$set(_vm.fields.invoice, "expires", $$v)},expression:"fields.invoice.expires"}})],1)],1)],1):_vm._e()],1):_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Fatura associada: ")]),_vm._v(" "+_vm._s(_vm.getFilename(_vm.fields.invoice.path))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('small',[_vm._v("(Para ver a fatura dirigir-se ao separador Faturas deste Tenant)")])])],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.method == 'create' ? 'Renovar' : 'Atualizar')+" ")])],1)],1)],1)])]}}],null,false,2238369373)})],1)],1):_vm._e()],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Subscrição gravada com sucesso ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }