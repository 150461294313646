<template>
  <v-container>
    <v-card class="mt-8">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Faturas a expirar</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :hide-default-footer="true"
          locale="pt-pt"
          item-key="index"
        >
        
            <template v-slot:item.tenant="{item}">
                <p><small>{{ item.tenant }}</small></p>
            </template>

          
            <template v-slot:item.title="{item}">
                <p><small>{{ item.title }}</small></p>
          </template>
          
          <template v-slot:item.days_left="{item}">
                <p><small>{{ item.days_left }}</small></p>
          </template>
          

          <template v-slot:item.actions="{item}">
          <IconView
            class="mr-2"
            @click="viewTenant(item)"
          />
        </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import IconView from '@/components/ui/IconView.vue';

export default{
components:{
  IconView
},
props: ['data'],
data(){
  return{
      loading: true,
      items:[],
      total: 0,
      headers:[
          {
          text: 'Cliente',
          sortable: false,
          value: 'tenant',
          },
          {
          text: 'Titulo',
          sortable: false,
          value: 'title',
          },     
          {
          text: 'Dias',
          sortable: false,
          value: 'days_left',
          },       
          { 
            text: 'Opções', 
            value: 'actions', 
            sortable: false 
          },
      ],
  }
},
mounted(){
  this.loading = true
  this.items = this.data.expiring_invoices
  this.total = this.data.expiring_invoices.length
  this.loading = false
},
methods:{
  viewTenant(tenant){
    this.$router.push('tenants/update/' + tenant.id);
  }
}
}

</script>