import BaseApi from "./BaseApi";

export default class Billings extends BaseApi{
    construct() {}

    static async search(params) {
        return await Billings.getAxiosInstance().get(Billings.getApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/c4/billings/";
    }
}