<template>
    <v-container>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="900px"
        >
            
            <v-card v-if="!this.loading">
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>                        
                        <span class="text-h5">Subscrição</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">      
                        <v-form class="mb-16" @submit.prevent="addSubscription">
                            <fieldset :disabled="loading">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <validation-provider v-slot="{ errors }" vid="Inicio" name="Inicio" rules="required">
                                                    <v-text-field
                                                        v-model="fields.subscription_start"
                                                        label="Início"
                                                        prepend-icon="mdi-calendar"
                                                        :error-messages="errors"
                                                        hint="Escolha uma data para desbloquear os restantes campos"
                                                        persistent-hint
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.subscription_start"
                                            @input="menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" vid="Tipo" name="Tipo" rules="required">
                                            <v-select
                                                :items="subscription_types"
                                                :error-messages="errors"
                                                label="Subscrição"
                                                :disabled="fields.subscription_start == null"
                                                item-text="type"
                                                item-value="id"
                                                v-model="fields.subscription_id"
                                                @change="changedSubscription"
                                            ></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <validation-provider v-slot="{ errors }" vid="Fim" name="Fim" rules="required">
                                                    <v-text-field
                                                        v-model="fields.subscription_end"
                                                        label="Fim"
                                                        :disabled="fields.subscription_id == null"
                                                        prepend-icon="mdi-calendar"
                                                        :error-messages="errors"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.subscription_end"
                                            @input="menu2 = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        
                                        <validation-provider v-slot="{ errors }" vid="typology" name="Tipologia" rules="required">
                                            <v-select
                                                v-model="fields.subscription_typology"
                                                hint="Escolha o tipo da subscrição"
                                                :items="['Gold', 'Diamond', 'Tailored', 'Trial']"
                                                :error-messages="errors"
                                                label="Tipo"
                                                persistent-hint
                                                :disabled="fields.subscription_id == null"
                                                return-object
                                                single-line
                                            ></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">                                        
                                        <validation-provider v-slot="{ errors }" vid="Preço" name="Preço" rules="required">
                                            <v-text-field 
                                                :error-messages="errors" 
                                                :disabled="fields.subscription_id == null"
                                                label="Preço" v-model="fields.price" 
                                                prepend-icon="mdi-currency-eur"></v-text-field>
                                        </validation-provider>
                                    </v-col>                                
                                    <v-col cols="12" md="4">
                                        <v-checkbox
                                            v-model="fields.paid"
                                            label="Paga"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4" v-if="fields.paid">
                                        <v-menu
                                                v-model="menu4"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <validation-provider v-slot="{ errors }" vid="paga_a" name="Paga_a" rules="required">
                                                        <v-text-field
                                                            v-model="fields.paid_at"
                                                            label="Paga em"
                                                            prepend-icon="mdi-calendar"
                                                            :error-messages="errors"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </template>
                                                <v-date-picker
                                                v-model="fields.paid_at"
                                                @input="menu4 = false"
                                                ></v-date-picker>
                                            </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="8"></v-col>
                                    <div id="invoicesDIV" v-if="!hasInvoice">
                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="fields.addInvoice"
                                                label="Associar fatura"
                                            />
                                        </v-col>
                                        <v-row v-if="fields.addInvoice">
                                            <v-col cols="12" md="6">
                                                <v-file-input
                                                    accept=".pdf"
                                                    label="Fatura"
                                                    v-model="fields.invoice.file"
                                                ></v-file-input>
                                            </v-col>        
                                            <v-col cols="12" md="6">                                            
                                                <validation-provider v-slot="{ errors }" vid="title" name="title" rules="required">
                                                    <v-text-field :error-messages="errors" label="Titulo" v-model="fields.invoice.title"></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12">
                                                <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                                    <v-textarea
                                                    name="input-7-1"
                                                    v-model="fields.invoice.description"
                                                    :error-messages="errors"
                                                    label="Descriçao"
                                                    ></v-textarea>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">                                            
                                                <validation-provider v-slot="{ errors }" vid="total" name="total" rules="required">
                                                    <v-text-field :error-messages="errors" label="Total" v-model="fields.invoice.total" prepend-icon="mdi-currency-eur"></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">                                            
                                                <v-menu
                                                    v-model="menu3"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="fields.invoice.expires"
                                                            label="Expira"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="fields.invoice.expires"
                                                    @input="menu3 = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-else>
                                        <v-col cols="12">
                                            <strong>Fatura associada: </strong>
                                            {{ getFilename(fields.invoice.path) }}
                                        </v-col>
                                        <v-col cols="12">
                                            <small>(Para ver a fatura dirigir-se ao separador Faturas deste Tenant)</small>
                                        </v-col>
                                    </div>
                                </v-row>   
                                <v-row class="mt-6" align="center" justify="space-around">
                                    <v-col align="center" justify="space-around">
                                        <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                            {{ method == 'create' ? 'Renovar' : 'Atualizar' }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </fieldset>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Subscrição gravada com sucesso
        </DialogSuccess>
    </v-container>        
</template>
<script>
import Subscription from "@/api/Subscription.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
    components:{
        DialogSuccess
    },
    async mounted(){
        this.loading = false
    },
    props:{
        subscription_types: Array,
        method: {
            typeof: String,
            default: 'create'
        },
        tenant: String,
        selectedSubscription:{
            default: null
        }
    },
    data(){
        return{
            hasInvoiceUpdate: false,
            success: false,
            hasInvoice: false, 
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            fields:{
                id: null,
                subscription_start: null,
                subscription_end: null,
                subscription_id: null,
                price: null,
                paid: false,
                paid_at: null,
                subscription_typology: '',
                invoice: {
                    object: null,
                    description: '',
                    total: '',
                    expires: null,
                    file: null,
                    id: null,
                    title: ''
                },
                addInvoice: false
            },
            loading: false, 
            dialog: false,
            label: 'Subscrição'
        }
    },
    watch:{
        selectedSubscription:{
            immediate: true,
            handler(val){
                if(val == null)
                    return
                    
                
                this.fields.subscription_id = val.subscription_id
                this.fields.price = val.price
                this.fields.paid = val.paid
                this.fields.paid_at = val.paid_at
                this.fields.subscription_end = val.subscription_end
                this.fields.subscription_start = val.subscription_start
                this.fields.subscription_typology = val.subscription_typology == 'null' ? null : val.subscription_typology
                this.fields.id = val.id


                if(val.invoice != null){
                    this.hasInvoice = true
                    this.fields.addInvoice = true
                    this.fields.invoice.paid = val.invoice.paid
                    this.fields.invoice.description = val.invoice.description
                    this.fields.invoice.path = val.invoice.path
                    this.fields.invoice.expires = val.invoice.expires_at

                    let array = val.invoice.path.split('/')
                    let filePath = array[array.length - 1]

                    this.fields.invoice.file = filePath
                    this.hasInvoiceUpdate = true
                }else this.hasInvoiceUpdate = false

            }
        }
    },
    methods:{
        changedSubscription(v){
            let sub = null
            this.subscription_types.forEach(element => {
                if(element.id == v)
                    sub = element
            });
            if(sub.price != null)
                this.fields.price = sub.price
            
            

            if(sub.days == null)
                return

            let date_start = new Date(this.fields.subscription_start)
            let date_end = date_start
            date_end.setDate(date_start.getDate() + sub.days)
            var todayDate = date_end.toISOString().slice(0, 10);
            this.fields.subscription_end = todayDate

            
        },
        getFilename(f){
            if(f == null)
                return ''
            if(f == undefined)
                return ''

            let array = f.split('/')
            return array[array.length - 1]
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadSubscriptions')
        },
        addSubscription(){
            const formData = new FormData()
            formData.append('subscription_typology', this.fields.subscription_typology)
            formData.append('subscription_start', this.fields.subscription_start)
            formData.append('subscription_end', this.fields.subscription_end)
            formData.append('subscription_id', this.fields.subscription_id)
            formData.append('addInvoice', this.fields.addInvoice)
            formData.append('price', this.fields.price)

            formData.append('file', this.fields.invoice.file)
            formData.append('tenant', this.tenant)
            formData.append('invoice', JSON.stringify(this.fields.invoice))
            formData.append('id', this.fields.invoice.id)
            formData.append('updateWithInvoice', this.hasInvoiceUpdate)
            formData.append('headerID', this.fields.id)
            formData.append('paid', this.fields.paid)
            formData.append('paid_at', this.fields.paid_at)


            
            Subscription[this.method](formData, this.fields.id).then(() => {

                this.success = true;

                this.loading = false;
                

            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        openDialog(){
            this.dialog = true
        },
        subStartChanged(dt){
            this.fields.subscription_start = dt
        },
        subEndChanged(dt){
            this.fields.subscription_end = dt
        },
    }
}
</script>