<template>
    <v-card>
        <v-card-subtitle>Nesta área poderá configurar variáveis importantes para o cliente, tais como horários de trabalho, variáveis de exportação, entre outros. Tenha em atenção que estes dados serão vitais para o normal funcionamento da aplicação do lado do cliente</v-card-subtitle>
        <v-card-text>
            <validation-observer ref="form" v-slot="{  }">
                <v-form @submit.prevent="submit">
                    <v-tabs centered v-model="tab" vertical background-color="primary">
                        <v-tab>
                            <v-icon left>
                            mdi-clock
                            </v-icon>
                            Horários
                        </v-tab>
                        <v-tab v-if='$root.session.hasPermission(["super", "meals_configuration.read"])'>
                            <v-icon left>
                            mdi-food-apple
                            </v-icon>
                            Refeições
                        </v-tab>
                        <v-tab v-if='$root.session.hasPermission(["super", "alerts_configuration.read"])'>
                            <v-icon left>
                            mdi-email
                            </v-icon>
                            Alertas
                        </v-tab>
                        <v-tab v-if='$root.session.hasPermission(["super", "branding.read"])'>
                            <v-icon left>
                            mdi-watermark
                            </v-icon>
                            Branding
                        </v-tab>
                        
                            <v-tab-item>
                                <v-card flat>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-card-title><small>Indique o número de horas de trabalho:</small></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <validation-provider v-slot="{ errors }" vid="work_hours" name="Horas trabalho" rules="required">
                                                        <v-text-field
                                                            v-model="fields.work_hours"
                                                            prepend-inner-icon="mdi-clock"
                                                            label="Horas de Trabalho *"
                                                            :error-messages="errors"
                                                        ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-card-title><small>Indique o dia de ínicio do mês:</small></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <validation-provider v-slot="{ errors }" vid="work_hours" name="Horas trabalho" rules="required"> 
                                                        <v-select
                                                            v-model="fields.starting_month"
                                                            :items="days_starting"
                                                            menu-props="auto"
                                                            label="Dias"
                                                            prepend-inner-icon="mdi-view-day-outline"
                                                            :error-messages="errors"
                                                        ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                    <v-card-title><small>Classifique as horas extra:</small></v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" class="mt-8">
                                                <v-range-slider
                                                    dense
                                                    v-model="rangeModel"
                                                    hint="Im a hint"
                                                    max="12"
                                                    min="0"
                                                    @change="rangeChanging"
                                                    >
                                                    <template v-slot:thumb-label="props">
                                                        {{ props.value }}
                                                    </template>
                                                </v-range-slider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <validation-provider v-slot="{ errors }" vid="50" name="50" rules="required">
                                                <v-text-field
                                                    :disabled="!checkbox50"
                                                    v-model="fields.extra_50"
                                                    prepend-inner-icon="mdi-clock"
                                                    label="50%"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <validation-provider v-slot="{ errors }" vid="75" name="75" rules="required">
                                                <v-text-field
                                                    v-model="fields.extra_75"
                                                    :disabled="!checkbox75"
                                                    prepend-inner-icon="mdi-clock"
                                                    label="75%"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <validation-provider v-slot="{ errors }" vid="100" name="100" rules="required">
                                                <v-text-field
                                                    v-model="fields.extra_100"
                                                    :disabled="!checkbox100"
                                                    prepend-inner-icon="mdi-clock"
                                                    label="100%"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12">
                                                <small>(Horas para além das Horas trabalhadas. Por exemplo, se colocar 1 hora nos 50% e um colaborador trabalhar 9 horas, a aplicação irá assumir 1 hora de trabalho nos 50%)</small>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
        

                            <v-tab-item v-if='$root.session.hasPermission(["super", "meals_configuration.read"])'>
                                <v-card flat>
                                    <v-card-title>Refeições Trabalho Diurno</v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="2">
                                                <p>Pequeno Almoço:</p>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-switch
                                                    class="mt-n1"
                                                    v-model="fields.day_breakfast"
                                                    inset
                                                    :label="fields.day_breakfast ? 'Sim' : 'Não'"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <p>Almoço:</p>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-switch
                                                    class="mt-n1"
                                                    v-model="fields.day_lunch"
                                                    inset
                                                    :label="fields.day_lunch ? 'Sim' : 'Não'"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <p>Jantar:</p>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <v-switch
                                                    class="mt-n1"
                                                    v-model="fields.day_dinner"
                                                    inset
                                                    :label="fields.day_dinner ? 'Sim' : 'Não'"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-title>Refeições Trabalho Noturno</v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="2">
                                                <p>Pequeno Almoço:</p>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-switch
                                                    class="mt-n1"
                                                    v-model="fields.night_breakfast"
                                                    inset
                                                    :label="fields.night_breakfast ? 'Sim' : 'Não'"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <p>Almoço:</p>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-switch
                                                    class="mt-n1"
                                                    v-model="fields.night_lunch"
                                                    inset
                                                    :label="fields.night_lunch ? 'Sim' : 'Não'"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <p>Jantar:</p>
                                            </v-col>
                                            <v-col cols="12" md="1">
                                                <v-switch
                                                    class="mt-n1"
                                                    v-model="fields.night_dinner"
                                                    inset
                                                    :label="fields.night_dinner ? 'Sim' : 'Não'"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item v-if='$root.session.hasPermission(["super", "alerts_configuration.read"])'>
                                <v-card flat>
                                    <v-card-title>
                                        Selecione os dias de semana em que
                                    </v-card-title>
                                    <v-card-subtitle>
                                        Deve receber diárias por fazer:
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="2">
                                                <v-checkbox
                                                    label="Segunda-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.monday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Terça-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.tuesday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Quarta-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.wednesday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Quinta-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.thursday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="4">                                                
                                                <v-checkbox
                                                    label="Sexta-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.friday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Sábado"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.saturday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Domingo"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_do.sunday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle>
                                        Dias de atraso:
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="fields.diaries_to_do_limit"
                                            prepend-inner-icon="mdi-clock"
                                            label="Limite de dias"
                                        ></v-text-field>
                                    </v-card-text>
                                    <v-card-subtitle>
                                        Deve receber diárias por aprovar:
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="2">
                                                <v-checkbox
                                                    label="Segunda-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.monday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Terça-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.tuesday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Quarta-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.wednesday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Quinta-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.thursday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="4">                                                
                                                <v-checkbox
                                                    label="Sexta-feira"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.friday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Sábado"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.saturday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="2">                                                
                                                <v-checkbox
                                                    label="Domingo"
                                                    color="red darken-3"
                                                    v-model="fields.diaries_to_approve.sunday"
                                                    hide-details
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-subtitle>
                                        Dias de atraso:
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="fields.diaries_to_approve_limit"
                                            prepend-inner-icon="mdi-clock"
                                            label="Limite de dias"
                                        ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item v-if='$root.session.hasPermission(["super", "branding.read"])'>
                                <v-card flat>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-card-text>
                                            <v-file-input
                                                v-model="fields.branding"
                                                :rules="rules"
                                                accept="image/png, image/jpeg, image/bmp"
                                                placeholder="Pick the logo"
                                                prepend-icon="mdi-camera"
                                                label="Logo"
                                                @change="onFileChange"
                                            ></v-file-input>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-img v-if="img_url" :src="img_url" 
                                            max-height="225"
                                            max-width="350"/>
                                    </v-col>
                                </v-row>
                            </v-card>
                            </v-tab-item>
                        </v-tabs>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed color="primary" type="submit">
                                Gravar
                            </v-btn>
                        </v-card-actions>
                </v-form>
            </validation-observer>
        </v-card-text>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Configurações alteradas com sucesso!
        </DialogSuccess>
    </v-card>
</template>
<script>
import Configurations from "@/api/Configurations.js";
import Branding from "@/api/Branding.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default{
    components:{
        DialogSuccess,
        ErrorSnackbar
    },
    props: ['tenant'],
    data: () => ({    
        rangeModel: [12, 12],       
        error: {
            title: '',
            message: '',
            show:false,
            showExtensive: false
        }, 
        availableExtrahours_50: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        availableExtrahours_75: [],
        availableExtrahours_100: [],
        checkbox50: false,
        checkbox75: false,
        checkbox100: false,
        img_url: null,
        tab : 0,
        rules: [
            value => !value || value.size < 1000000 || 'Logo size should be less than 1 Mb!',
        ],   
        days_starting: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28   
        ],
        loading: false,
        success: false,
        fields:{
            id: null,
            day_breakfast: null,
            day_dinner: null,
            day_lunch: null,
            night_breakfast: null,
            night_dinner: null,
            night_lunch: null,
            work_hours: null,
            extra_50: null,
            extra_75: null,
            extra_100: null,
            diaries_to_approve_limit: null,
            diaries_to_do_limit: null,
            branding: null,
            diaries_to_do:{
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            },
            diaries_to_approve:{
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }
        }
    }),
    async mounted(){
        this.loading = true
        await Configurations.get(this.tenant).then(({data}) => {
            this.fields = data.data
            if(data.logo != null)
                this.img_url = data.logo.replace(/([^:])(\/\/+)/g, '$1/');
            
            if(this.fields.diaries_to_do == null){
                this.fields.diaries_to_do = {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false
                }
            }
            if(this.fields.diaries_to_approve == null){
                this.fields.diaries_to_approve = {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false
                }
            }

            this.fields.starting_month = data.data.starting_month
            this.fields.work_hours = data.data.work_hours
            this.loading = false
        });
    },
    methods:{
        rangeChanging(ranger){
            let smallest = ranger[0] > ranger[1] ? ranger[1] : ranger[0]
            let biggest = ranger[0] > ranger[1] ? ranger[0] : ranger[1]

            this.fields.extra_50 = smallest
            this.fields.extra_75 = biggest - smallest
            this.fields.extra_100 = 12 - biggest
        },
        checkAvailableHours(p){
            if(p == '50'){
                if(this.checkbox50 == false)
                    this.fields.extra_50 = 0
            
            }else if(p == '75'){
                let max = 12
                max = max - this.fields.extra_50
                this.availableExtrahours_75 = []
                for (let i = 0; i < max; i++) {
                    this.availableExtrahours_75.push(i)
                }
                if(this.checkbox75 == false)
                    this.fields.extra_75 = 0
            }else{
                let max = 12
                max = max - this.fields.extra_50 - this.fields.extra_75
                this.availableExtrahours_100 = []
                for (let i = 0; i < max; i++) {
                    this.availableExtrahours_100.push(i)
                }
                if(this.checkbox100 == false)
                    this.fields.extra_100 = 0
            }
        },
        onFileChange(file){
            if(file == null)
                return

            const reader = new FileReader();

            reader.onload = e => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onSuccess(){
            this.success = false
        },
        submit(){
            if(this.tab != 3){
                Configurations.update(this.tenant, this.fields, this.fields.id).then(() => {
    
                    this.success = true;

                    this.loading = false;
                    
                    }).catch(err => {
                    this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = "Erro " + err.response.status;

                    this.error.message = err.response.data.message;

                });
            }else{
                const formData = new FormData();
                formData.append('file', this.fields.branding)
                Branding.create(this.tenant, formData).then(() => {
    
                    this.success = true;

                    this.loading = false;
                    
                    }).catch(err => {
                    this.loading = false;

                    if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                    }

                    this.error.title = "Erro " + err.response.status;

                    this.error.message = err.response.data.message;

                });
            }
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
            },
            {
            text: 'Configurações globais',
            disabled: true,
            to: window.location.href,
            exact: true,
            },
        ];
        }
    }
}
</script>