import BaseApi from "./BaseApi";

export default class Branding extends BaseApi{
    construct() {}

    static async create(tenant, params) {
        return await Branding.getAxiosInstance().post(Branding.getFullApiUrl(tenant),params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/branding/";
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/branding/";
    }
}