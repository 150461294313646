<template>
<v-theme-provider root>
  <v-app :style="{'--group-selected': $vuetify.groupSelectedColor}">
      <v-navigation-drawer
        app
        :mini-variant="showMini"
        v-model="showMenu"
        width="300"
      >
      <v-list nav>
        <template v-if="loading">
          <v-skeleton-loader
            v-for="i in items.length"
            v-bind:key="i"
            type="list-item"
          ></v-skeleton-loader>
        </template>
        <template v-for="item in items" v-else>
          <v-tooltip right color="primary" :key="item.title" v-if="item.items == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item link v-bind="attrs" :to="item.link" :exact="item.exact" v-on="on">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item> 
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>  
          <v-tooltip right color="primary" :key="item.title" v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-list-group :group="item.group" v-bind="attrs" v-on="on">
                <template v-slot:activator>
                  <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                
                <template v-for="child in item.items">
                  <v-tooltip right color="primary" :key="child.title" v-if="child.has_permission">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item link :to="child.link" :exact="child.exact" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                          <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item> 
                    </template>
                    <span>{{ child.title }}</span>
                  </v-tooltip>  
                </template>
              </v-list-group> 
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </template>
        <v-divider></v-divider> 
      </v-list>
    </v-navigation-drawer>



    <v-app-bar dense fixed app :clipped-left="false" v-if="!loading">
      <v-tooltip right color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click="openMenu"></v-app-bar-nav-icon>
        </template>
        <span>Menu</span>
      </v-tooltip>

      <v-spacer></v-spacer>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon link to="/admin/notifications">
              <v-badge :content="notifications" :value="notifications" color="error" overlap>
                <v-icon :color="notifications > 0 ? 'secondary' : ''">mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Notificações</span>
        </v-tooltip>

        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" link :to="'/admin/settings/my-user'" icon>
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Conta</span>
        </v-tooltip>

        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="toggleTheme">
              <v-icon>{{$vuetify.theme.dark ? "mdi-white-balance-sunny" :  "mdi-weather-night"}}</v-icon>
            </v-btn>
          </template>
          <span>{{$vuetify.theme.dark ? "Modo Claro" : "Modo Escuro"}}</span>
        </v-tooltip>

        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="logout">
              <v-icon color="error">mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Sair</span>
        </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-progress-linear v-if="loading"
      indeterminate
      class="global-loader"
    ></v-progress-linear>
      <v-container v-on="$listeners" fluid v-else>
          <v-row>
              <v-col offset="0" cols="12" offset-lg="1" lg="10">
                  <router-view @read-notifications="notifications = 0"></router-view>
              </v-col>
          </v-row>
      </v-container>
    </v-main>
    <v-footer padless>
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong class="primary--text">HefestoV{{getVersion()}}</strong>
      </v-col>
    </v-footer>
  </v-app>
</v-theme-provider>
</template>
<script>
import User from "@/api/User.js"
import Notification from "@/api/Notification.js"

export default {
  components: {
    
  },
  watch: {
    showMenu: {
      immediate: false,
      handler (val) {
         localStorage['menu-show'] = val;
      },
    },
    showMini: {
      immediate: false,
      handler (val) {
         localStorage['menu-mini'] = val;
      },
    },
    $route: {
        immediate: true,
        handler() {
          if(this.$root.session == undefined){
            return
          }

          let theme = this.$root.session.theme;

          theme = theme.length == 0 ? this.$themes[0] : theme
          
          this.setTheme(theme);

          this.setCssVars();
        }
    },
  },
  data: () => ({
      notifications: null,
      newNotification: false,
      showMenu: false,
      showMini: false,
      isRender: true,
      user: {},
      items: [],
      loading: true,
      groupSelectedColor: {},
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
  }),
  beforeMount(){
    this.$vuetify.theme.dark = +localStorage['dark-mode'];

    this.listNotifications()

    User.getSession(false).then(({data}) => {
      this.user = User.getInstance(data);

      let theme = data.theme;

      theme = theme.length == 0 ? this.$themes[0] : theme

      this.setTheme(theme);

      this.$root.session = this.user;


      localStorage['auth'] = 1;

      this.setMenu();

      this.loading = false;

      this.setCssVars();

    }).catch((error) => {

      console.error(error);

      localStorage['auth'] = 0;

      this.$router.push('/');
    });

    this.showMini = localStorage['menu-mini'] == "true";

    let showMenu = localStorage['menu-show'];

    this.showMenu = showMenu != undefined ? showMenu == "true" : true;

    this.showMenu = this.showMenu || !this.$vuetify.breakpoint.mdAndDown;
  },
  methods:{
    getVersion(){
            return process.env.VUE_APP_SECRET_VERSION
    },
    listNotifications() {
      Notification.list(1).then(({data}) => {
          this.notifications = data.length
      });
    },
    setCssVars(){
      let color = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primaryLight
          : this.$vuetify.theme.themes.light.primaryLight;

      this.groupSelectedColor = {
        '--group-selected': color,
      };
    },
    toggleTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      localStorage['dark-mode'] = Number(this.$vuetify.theme.dark);
    },
    logout() {
      User.logout(this.$root.session.id)
        .then(() => {
          localStorage['auth'] = 0;

          this.$router.push('/');
        });
    },
    openMenu(){

      if(!this.$vuetify.breakpoint.mdAndDown){

        this.showMini = !this.showMini;

        return;
      }

      this.showMini = false;

      this.showMenu = !this.showMenu;
    },
    setMenu() {
      
      let menu = [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          has_permission: this.$root.session.hasPermission(["super", "works.read", "diaries.read", "invoices.read", "subscriptions_read", "online_users.read"]),
          exact: true,
          link: "/admin",
          items:[],
        },
        {
          title: "Tenants",
          icon: "mdi-account",
          has_permission: this.$root.session.hasPermission(["super", "tenants.read"]),
          exact: true,
          link: "/tenants",
          items:[],
        },
        {
          title: "Sincronização",
          icon: "mdi-account",
          has_permission: this.$root.session.hasPermission(["super", "synchronization.write"]),
          exact: true,
          link: "/sync",
          items:[],
        },
        {
          title: "Subscrições",
          icon: "mdi-playlist-check",
          has_permission: this.$root.session.hasPermission(["super", "subscriptions.read"]),
          exact: true,
          link: "/subscriptions_types",
          items:[],
        },
        {
          title: "Pagamentos",
          icon: "mdi-account-credit-card-outline",
          has_permission: this.$root.session.hasPermission(["super", "payments.read"]),
          exact: true,
          link: "/payments",
          items:[],
        },
        {
        title: "Definições",
        icon: "mdi-cog",
        has_permission: this.$root.session.hasPermission(["super", "roles.read", "users.read", "logs.read"]),
        link: "",
        group:"/internal_management",
        exact: false,
        items:[
          {
            title: "Gestão de Features",
            icon: "mdi-star-box",
            has_permission: this.$root.session.hasPermission(["super", "features.read"]),
            exact: true,
            link: "/internal_management/features",
            items:[],
          },
          {
            title: "Gestão de Roles",
            icon: "mdi-shield-account",
            has_permission: this.$root.session.hasPermission(["super", "roles.read"]),
            link: "/internal_management/roles",
            exact: false,
            items:[],
          },
          {
            title: "Gestão de Utilizadores",
            icon: "mdi-account-multiple",
            has_permission: this.$root.session.hasPermission(["super", "users.read"]),
            link: "/internal_management/users",
            exact: false,
            items:[],
          },
          {
            title: "Logs",
            icon: "mdi-paperclip",
            link: "/internal_management/logs",
            has_permission: this.$root.session.hasPermission(["super", "logs.read"]),
            exact: false,
            items:[],
          },
        ],
      }
      ];

      this.items = menu.filter((item) => {
        
        return item.has_permission;
      })
    }
  },
};
</script>
<style scoped>
  .v-list-group--active{
    background-color: var(--group-selected);
    border-radius: 4px;
  }
  .fixedBottom {
    position: fixed !important;
    bottom: 0 !important;
    width: 100%;
  }
</style>