import BaseApi from "./BaseApi";

export default class Logs extends BaseApi{
    construct() {}

    static async search(params) {
        return await Logs.getAxiosInstance().get(Logs.getApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/c4/logs/";
    }

}