import BaseApi from "./BaseApi";
export default class TenantEticadata extends BaseApi{
    construct() {}

    static async create(params) {
        let url = TenantEticadata.getApiUrl()
        
        return await TenantEticadata.getAxiosInstance().post(url,params);
    }

    static async populate(params) {
        let url = TenantEticadata.getApiUrl() + 'populate'
        
        return await TenantEticadata.getAxiosInstance().post(url,params);
    }
  
    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/tenant-eticadata";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/tenant-eticadata/";
    }
}