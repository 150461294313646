<template>
    <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Gestão de Pagamentos</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16 elevation-10"
          locale="pt-pt"
        >
        <template v-slot:item.paid="{item}">
          {{item.paid ? 'Sim' : 'Não'}}
        </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewPayment(item)"
            />
            <IconRemove
              v-if="$root.session.hasPermission(['super', 'payments.delete']) && item.paid == false"
              class="mr-2"
              @on-submit="deletePayment(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetPayment">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchPayment">
            
     

  

            
            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>

      <v-dialog
        v-model="dialog"
        v-if="selected_payment != null"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Pagamento</v-toolbar>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" md="3">
                  <v-chip outlined class="mt-4">
                    <v-icon color="success" v-if="selected_payment.paid">mdi-check-circle</v-icon>
                    <v-icon color="error" v-else>mdi-alert-circle</v-icon>
                    <span class="ml-2">{{selected_payment.paid ? 'Pago' : 'Por pagar'}}</span>
                  </v-chip>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field 
                    :readonly="true"
                    label="Tenant"
                    v-model="selected_payment.tenants_company_id"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field 
                    :readonly="true"
                    label="De"
                    v-model="selected_payment.created_at"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field 
                    :readonly="true"
                    label="Total (€)"
                    v-model="selected_payment.value"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field 
                    :readonly="true"
                    label="Método"
                    v-model="selected_payment.payment_method"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="Observações"
                    v-model="selected_payment.obs"
                    :readonly="true"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                class="error"
                @click="dialog.value = false"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>


    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Billings from "@/api/Billings.js";
import Tenant from "@/api/Tenant.js"

export default {
  components: {
    IconRemove,
    IconView
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchPayment()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Hefesto - Gestão de Pagamentos";

  },
  data: () => ({
    dialog: false,
    selected_payment: null,
    headers: [
      {
        text: 'Criado a',
        sortable: false,
        value: 'created_at',
      },
      {
        text: 'Tenant',
        sortable: false,
        value: 'tenants_company_id'
      },
      {
        text: 'Preço (€)',
        sortable: false,
        value: 'value',
      },
      {
        text: 'Método Pagamento',
        sortable: false,
        value: 'payment_method',
      },
      {
        text: 'Pago',
        sortable: false,
        value: 'paid',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      just_paid: false,
      just_not_paid: false,
      tenant: null,
      method: null
    },
    tenants:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "payments.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    isPaid(i){
      return i.paid == 1
    },
    fillBaseData() {

      if(localStorage["payments-global-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["payments-global-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }

      Tenant.list().then(({data}) => {
        this.tenants = data;
      });
    },
    searchPayment(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        deleted: this.filter.deleted
      };
    
      Object.assign(request, filter);

      localStorage["payments-global-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Billings.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetPayment()
    {
      this.filter = {};

      this.searchPayment();
    },
    viewPayment(item){
      this.selected_payment = item
      this.dialog = true
    },
    deletePayment(item){
      Billings.delete(item.id)
        .then(() => {
          this.searchPayment();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Gestão de pagamentos',
          disabled: false,
          to: '/payments',
          exact: true,
        },
      ];
    }
  }
};
</script>
