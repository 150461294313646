<template>
    <v-card class="elevation-10">
      <v-card-text>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">
              <v-row>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="Subscrição" name="Subscrição" rules="required">
                    <v-text-field
                      v-model="fields.type"
                      prepend-inner-icon="mdi-account-details"
                      label="Subscrição *"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="Preço" name="Preço" rules="required">
                    <v-text-field
                      v-model="fields.price"
                      type="number"
                      prepend-inner-icon="mdi-currency-eur"
                      label="Preço *"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="Dias" name="Dias" rules="|">
                    <v-text-field
                      v-model="fields.days"
                      type="number"
                      prepend-inner-icon="mdi-calendar-alert"
                      label="Dias"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>              
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                  Gravar
                </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Subscrição gravada com sucesso!
          </DialogSuccess>
        </validation-observer>
      </v-card-text>
    </v-card>
  </template>
  <script>
  import SubscriptionTypes from "@/api/SubscriptionTypes.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components: {
      DialogSuccess
    },
    props: {
     sub: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    sub: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      fields: {
        id: null,
        type: null,
        price:null,
        days:null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/subscriptions_types');
      },
      fillBaseData(){
        
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
          SubscriptionTypes[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  