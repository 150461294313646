<template>
    <v-card class="elevation-10">
      <v-tabs v-model="tab" background-color="primary">
        <v-tab href="#Geral">Geral</v-tab>
        <v-tab href="#Subscrições" v-if='this.$root.session.hasPermission(["super", "subscriptions.read"])'>Subscrições</v-tab>
        <v-tab href="#Acessos" v-if='this.$root.session.hasPermission(["super", "accesses.read"])' :disabled="method == 'create' ? true : false">Acessos</v-tab>
        <v-tab v-if='this.$root.session.hasPermission(["super", "invoices.read"])' href="#Faturas" :disabled="method == 'create' ? true : false">Faturas</v-tab>
        <v-tab href="#Features" v-if='this.$root.session.hasPermission(["super", "features.read"])' :disabled="method == 'create' ? true : false">Features</v-tab>
        <v-tab href="#Configurações" :disabled="method == 'create' ? true : false">Configurações</v-tab>
        <v-tab href="#Alertas" :disabled="method == 'create' ? true : false">Alertas</v-tab>
        <v-tab href="#Eticadata"  v-if="hasFeature('eticadata.config')" :disabled="method == 'create' ? true : false">Eticadata</v-tab>
      </v-tabs>
      
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item value="Geral">
            <validation-observer ref="form" v-slot="{ invalid }">      
              <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                  ></v-progress-linear>
                <fieldset :disabled="loading">
                  <v-row>
                    <v-col cols="12" class="mb-n10 mt-n4">
                      <v-switch
                          style="float: right; color: red;"
                          v-model="fields.active"
                          inset
                          color="success"
                          :label="fields.active ? 'Ativo' : 'Inativo'"
                      ></v-switch>
                    </v-col>     
                    <v-col cols="12" md="3">
                        <v-text-field
                          :disabled="method == 'create'"
                          v-model="fields.reference"
                          prepend-inner-icon="mdi-code-string"
                          label="Referência"
                          clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                        <v-text-field
                          v-model="fields.name"
                          prepend-inner-icon="mdi-text-box"
                          label="Nome"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="nif" name="Nif" rules="required">
                        <v-text-field
                          v-model="fields.nif"
                          prepend-inner-icon="mdi-barcode"
                          label="Nif"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="telephone" name="Telefone" rules="required">
                        <v-text-field
                          v-model="fields.telephone"
                          prepend-inner-icon="mdi-phone"
                          label="Telefone"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required">
                        <v-text-field
                          v-model="fields.email"
                          prepend-inner-icon="mdi-email"
                          label="Email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>            
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" vid="address" name="Endereço" rules="|">
                        <v-text-field
                          v-model="fields.address"
                          prepend-inner-icon="mdi-map-marker"
                          label="Endereço"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="2">
                      <validation-provider v-slot="{ errors }" vid="coin" name="Moeda" rules="required">
                        <v-select
                          :items="['€', 'R$']"
                          label="Moeda *"
                          v-model="fields.coin"
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="2">
                      <validation-provider v-slot="{ errors }" vid="language" name="Língua" rules="required">
                        <v-select
                          :items="['PT', 'EN']"
                          label="Língua *"
                          v-model="fields.language"
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="domain" name="Domínio" rules="required">
                        <v-text-field
                          v-model="fields.dominio"
                          prepend-inner-icon="mdi-link"
                          label="Domínio"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="maximo_workers" name="MáximoColaboradores" rules="|">
                        <v-text-field
                          v-model="fields.max_workers"
                          prepend-inner-icon="mdi-counter"
                          label="Máximo de colaboradores"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      Pretende informações de marketing?
                      <v-switch
                          class="mt-n1"
                          v-model="fields.marketing"
                          inset
                          :label="fields.marketing ? 'Sim' : 'Não'"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="4">
                      Aceitou os termos e condições?
                      <v-switch
                          class="mt-n1"
                          readonly
                          v-model="fields.accepted_terms"
                          inset
                          :label="fields.accepted_terms ? 'Sim' : 'Não'"
                      ></v-switch>
                    </v-col>
                    </v-row>
                    
                    <v-row>
                      <v-col cols="12" md="4">
                        <DatePicker :disabled="true" label="Início subscrição" :date="fields.subscription_start" return-object/>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          :disabled="true"
                          :items="subscription_types"
                          label="Subscrição"
                          item-text="type"
                          item-value="id"
                          v-model="fields.subscription_id"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <DatePicker :disabled="true" label="Fim subscrição" :date="fields.subscription_end" return-object/>
                      </v-col>
                    </v-row>
                  <v-row>
                    <Subscription :tenant="fields.tenant" :fields="fields" :subscription_types="subscription_types" ref="subscriptions"/>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                        Gravar
                      </v-btn>
                    </v-col>
                  </v-row>
                </fieldset>
              </v-form>
            </validation-observer>
          </v-tab-item>   

          <v-tab-item value="Subscrições">
            <ListSubscriptions @refresh="loadTenant" :tenant="fields.tenant" />
          </v-tab-item>
          <v-tab-item value="Acessos">
            <ListUsers :tenant="fields.tenant"/>
          </v-tab-item>
          <v-tab-item value="Faturas">
            <ListInvoices :tenant="fields.tenant"/>            
          </v-tab-item>
          <v-tab-item value="Features">
            <FeaturesList :tenant="fields.tenant"/>
          </v-tab-item>
          <v-tab-item value="Configurações">
            <Configurations :tenant="fields.tenant"/>
          </v-tab-item>
          <v-tab-item value="Alertas">
            <ListAlerts :tenant="fields.tenant" />
          </v-tab-item>
          <v-tab-item value="Eticadata" v-if="hasFeature('eticadata.config')">
            <Eticadata :data="fields.eticadata" :tenant="fields.tenant" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Cliente gravado com sucesso!
      </DialogSuccess>
    </v-card>
</template>
<style>
.v-tab{
  color: white !important;
}
.v-tab--active{
  color: rgb(148, 143, 143) !important;
}
</style>
<script>

import FeaturesList from '@/components/tenants/features/FeaturesList.vue';
import ListInvoices from '@/components/tenants/invoices/ListInvoices.vue';
import ListAlerts from '@/components/tenants/alerts/ListAlerts.vue';
import ListSubscriptions from '@/components/tenants/subscriptions/ListSubscriptions.vue';
import ListUsers from '@/components/tenants/accesses/ListUsers.vue';
import Configurations from '@/components/tenants/configurations/Configurations.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Tenant from "@/api/Tenant.js";
import SubscriptionHistory from "@/api/SubscriptionHistory.js";
import Subscription from '@/components/tenants/main/Subscription.vue'
import DatePicker from '@/components/ui/DatePicker'
import SubscriptionTypes from "@/api/SubscriptionTypes.js";
import Eticadata from '@/components/tenants/eticadata/Eticadata.vue'

export default {
  components: {
    ListAlerts,
    Subscription,
    DialogSuccess,
    ListInvoices,
    ListUsers,
    ListSubscriptions,
    Configurations,
    DatePicker,
    FeaturesList,
    Eticadata
  },
  props:{
    method: String
  },
  watch: { 
    tenant: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;


      }
    },
  },
  beforeCreate(){
    
  },
  data: () => ({
      subscription_types: [],
      subscription_history: [],
      tab: null,
      success: false, 
      loading: false,
      fields: {
          id: null,
          name: '',
          eticadata: null,
          nif: '',
          telephone: '',
          email: '',
          address: '',
          dominio: '',
          tenant: '',
          subscription_start: '',
          subscription_end: '',
          subscription_id: '',
          marketing: false,
          renew: false,
          active: true,
          reference: null,
          coin: null,
          language: null,
          accepted_terms: false
      }
  }),
  async mounted(){


    if(this.method == 'update'){
      await this.loadTenant()
      await SubscriptionTypes.list().then(({data}) => {
          this.subscription_types = data;
          this.loading = false
      });

      await SubscriptionHistory.list().then(({data}) => {
        this.subscription_history = data;
      });

      // Verificar se vem das notificações
      if(this.$route.params.invoice_id != undefined){
        this.tab = this.$route.params.place
      }
    }    
  },
  methods:{
    loadTenant(){
      Tenant.find(this.$route.params.id).then(({data}) => {
          this.fields.id = data.id
          this.fields.name = data.name
          this.fields.nif = data.nif
          this.fields.telephone = data.telephone
          this.fields.email = data.email
          this.fields.address = data.address
          this.fields.dominio = data.domain
          this.fields.tenant = data.tenant_id
          this.fields.subscription_start = data.subscription_start
          this.fields.subscription_end = data.subscription_end
          this.fields.subscription_id = data.subscription_id
          this.fields.marketing = data.marketing
          this.fields.active = data.active
          this.fields.max_workers = data.max_workers
          this.fields.reference = data.reference
          this.fields.coin = data.coin
          this.fields.language = data.language == null ? 'PT' : data.language.toUpperCase()
          this.fields.accepted_terms = data.accepted_terms
          this.fields.eticadata = data.eticadata
          this.fields.features = data.features
      });
    },
    hasFeature(name){
      let r = false
      if(this.fields == null)
        return false;

      if(this.fields.features == null)
        return false;

      this.fields.features.forEach(element => {
        if(element == name)
          r = true
      });
      return r
    },
    getSubType(sub){
      let name = ''
      this.subscription_types.forEach(element => {
        if(element.id == sub)
          name = element.type
      });
      return name
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/tenants');
    },
    submit() {
      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.renew == true){
          if(this.fields.subscription_start == null)
            return
          if(this.fields.subscription_end == null)
            return
          if(this.fields.subscription_id == null)
            return
        }

        Tenant[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
  