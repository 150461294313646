import BaseApi from "./BaseApi";
export default class Synchronization extends BaseApi{
    construct() {}


    static async getFields(table) {
        return await Synchronization.getAxiosInstance().get(Synchronization.getApiUrl() + table);
    }

    static async import(params) {
        let url = Synchronization.getApiUrl()
        
        return await Synchronization.getAxiosInstance().post(url,params);
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/synchronization/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/synchronization/";
    }
}