<template>
  <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <v-container v-if="!loading">
        <v-row>
          <v-col cols="12">
            <CardRows :data="data"/>
          </v-col>
          <v-col cols="12" md="6" v-if='$root.session.hasPermission(["super", "invoices.read"])'>
            <ExpiringInvoices :data="data" class="mt-n12"/>
          </v-col>
          <v-col cols="12" md="6" v-if='$root.session.hasPermission(["super", "invoices.read"])'>
            <ExpiredInvoices :data="data" class="mt-n12" />
          </v-col>
          <v-col cols="12" md="6" v-if='$root.session.hasPermission(["super", "invoices.read"])'>
            <ExpiringSubscriptions :data="data" class="mt-n12"/>
          </v-col>
          <v-col cols="12" md="6" v-if='$root.session.hasPermission(["super", "subscriptions.read"])'>
            <ExpiredSubscriptions :data="data" class="mt-n12" />
          </v-col>
          <v-col cols="12" md="12" v-if='$root.session.hasPermission(["super", "online_users.read"])'>
            <OnlineUsers :data="data" class="mt-n12"/>
          </v-col>
        </v-row>
        

      </v-container>
  </div>
</template>
<script>

import CardRows from '@/components/dashboard/CardRows.vue';
import ExpiringSubscriptions from '@/components/dashboard/subscriptions/Expiring.vue';
import ExpiredSubscriptions from '@/components/dashboard/subscriptions/Expired.vue';
import ExpiringInvoices from '@/components/dashboard/invoices/Expiring.vue';
import ExpiredInvoices from '@/components/dashboard/invoices/Expired.vue';
import OnlineUsers from '@/components/dashboard/OnlineUsers.vue';
import Dashboard from "@/api/Dashboard.js"

export default{
  components:{
    CardRows,
    ExpiringSubscriptions,
    ExpiredSubscriptions,
    ExpiringInvoices,
    ExpiredInvoices,
    OnlineUsers
  },
  data(){
    return{
      loading: true,
      data: null,
      name: '',
      loadedMessage: '',
      loaderMessages: []
    }
  },
  async mounted(){
    this.loading = true
    await Dashboard.get()
    .then((data) => {
      this.data = data.data
    }).catch(() => {

    })
    this.loading = false

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
      ];
    }
  }
}
</script>