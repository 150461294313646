<template>
  <div>
    
      <div style="position:relative">
      

      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :loading="loading"
        :footer-props="tableFooter"
        :options.sync="options"
        class="mt-16 elevation-10"
        locale="pt-pt"
      >

      <template v-slot:item.name="{item}">
          <p><small>{{ item.name }}</small></p>
      </template>

    
    <template v-slot:item.email="{item}">
          <p><small>{{ item.email }}</small></p>
    </template>

    
    <template v-slot:item.last_seen="{item}">
      <p v-if="item.last_seen != null"><small>{{ checkLastSeen(item.last_seen) }}</small></p>
      <p v-else><small>(Nunca iniciou sessão)</small></p>
    </template>
    
    
    <template v-slot:item.status="{item}">
      <status-indicator v-if="hourPassed(item.last_seen)" :status="passedMoreTwoHours(item.last_seen) ? 'negative' : 'intermediary'" />
      <status-indicator v-else :status="item.active_session ? 'positive' : 'negative'" />
    </template>

      </v-data-table>
    </div>
    

  </div>
</template>
<script>
import Dashboard from "@/api/Dashboard.js";

export default {
components: {
  
},
watch: {
  'options': {
    immediate: false,
    handler () {
      setTimeout(() => {this.searchUser()}, 10);
    },
    deep: true,
  },
},
beforeCreate(){

},
data: () => ({
  headers:[
      {
      text: 'Utilizador',
      sortable: false,
      value: 'name',
      },
      {
      text: 'Email',
      sortable: false,
      value: 'email',
      },
      {
      text: 'Última atividade',
      sortable: false,
      value: 'last_seen',
      },
      {
      text: '',
      sortable: false,
      value: 'status',
      },
  ],
  items: [],
  total: 0,
  loading: false,
  searching: false,
  tableFooter: {
    'items-per-page-options': [5, 10, 15, 20],
    'items-per-page-text': "Linhas por página"
  },
  options: {},
  speedDial:false,
  filter: {
    name: null,
    display_name:null,
    user_id:null,
  },
  users:[]
}),
mounted(){

  if(!this.$root.session.hasPermission(["super", "roles.read"])) {
    this.$router.push('/admin');
  }

  this.fillBaseData();
},
methods:{
  passedMoreTwoHours(dt){
      if(dt == null)
        return null
        
      
      let lastSeen = new Date(dt)
      let today = new Date()

      var diff =(today.getTime() - lastSeen.getTime()) / 1000;
      diff /= (60 * 60);
      diff = Math.abs(Math.round(diff));

      if(diff >= 2)
        return true

      return false
    },
  checkLastSeen(dt){
      if(dt == null)
        return null
        
      let currentDate = new Date()
      let lastSeen = new Date(dt)
      let difference = currentDate.getTime() - lastSeen.getTime()
      let hoursMilli = 1000 * 60 * 60; // milliseconds * seconds * minutes

      if (Math.abs(difference) < hoursMilli) {
        return dt;
      }else{
        return dt + ' (A sessão pode ter ficado aberta)'
      }
    },
    hourPassed(dt){
        if(dt == null)
          return null
          
        
        let lastSeen = new Date(dt)
        let today = new Date()
        

        if(today.getHours() != lastSeen.getHours()){
          return true;
        }

        
        if(today.getMinutes() - lastSeen.getMinutes() >= 15)
          return true

        return false
      },
    fillBaseData() {

      if(localStorage["online-users-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["online-users-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUser(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      /*let filter = {...this.filter};

      Object.assign(request, filter);*/
      
      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      

      localStorage["online-users-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Dashboard.lastSeen(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
  },
computed: {
  crumbs: function() {
    return [
      {
        text: 'Gestão Interna',
        disabled: false,
        to: '#',
        exact: true,
      },
      {
        text: 'Gestão de Roles',
        disabled: true,
        to: window.location.href,
        exact: true,
      },
    ];
  }
}
};
</script>
