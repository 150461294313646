import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Layout from '../Layout.vue'
import Dashboard from '../views/Dashboard.vue'
import Notifications from '../views/Notifications.vue'
import Tenants from '../views/Tenants.vue'
import CreateTenant from '../components/tenants/CreateTenant'
import UpdateTenant from '../components/tenants/UpdateTenant'
import Roles from '../views/Roles.vue'
import Features from '../views/Features.vue'
import Permissions from '../views/Permissions.vue'
import Logs from '../views/Logs.vue'
import NotFound from '../views/NotFound.vue'
import CreateRole from '../components/roles/CreateRole.vue'
import UpdateRole from '../components/roles/UpdateRole.vue'
import CreateFeature from '../components/features/CreateFeature.vue'
import UpdateFeature from '../components/features/UpdateFeature.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import Users from '../views/settings/users/Users.vue'
import Synchronization from '../views/synchronization/Synchronization.vue'

import SubscriptionsTypes from '../views/subscriptions_types/SubscriptionsTypes.vue'
import CreateSubscriptionType from '../views/subscriptions_types/CreateSubscriptionType.vue'
import UpdateSubscriptionType from '../views/subscriptions_types/UpdateSubscriptionType.vue'

import Payments from '../views/payments/Payments.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/admin',
                component: Dashboard
            },
            {
                path: '/tenants',
                component: Tenants
            },
            {
                path: '/payments',
                component: Payments
            },
            {
                path: '/subscriptions_types',
                component: SubscriptionsTypes
            },
            {
                path: '/subscriptions_types/create',
                component: CreateSubscriptionType,
            },
            {
                path: '/subscriptions_types/update/:id',
                component: UpdateSubscriptionType,
            },
            {
                path: '/admin/notifications',
                component: Notifications,
            },
            {
                path: '/tenants/Create',
                component: CreateTenant
            },
            {
                path: '/tenants/update/:id',
                component: UpdateTenant
            },
            {
                path: '/tenants/update/:id/:place/:invoice_id',
                component: UpdateTenant
            },
            {
                path: '/internal_management/roles',
                component: Roles
            },
            {
                path: '/internal_management/features',
                component: Features
            },
            {
                path: '/internal_management/users',
                component: Users
            },
            {
                path: '/internal_management/permissions',
                component: Permissions
            },
            {
                path: '/internal_management/logs',
                component: Logs
            },
            {
                path: '/admin/settings/roles/create',
                component: CreateRole,
            },
            {
                path: '/admin/settings/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/admin/settings/features/create',
                component: CreateFeature,
            },
            {
                path: '/admin/settings/features/:id',
                component: UpdateFeature,
            },
            {
                path: '/admin/settings/my-user',
                component: UpdateUser
            },
            {
                path: '/admin/settings/createuser',
                component: CreateUser
            },
            {
                path: '/admin/settings/users/create',
                component: CreateUser,
            },
            {
                path: '/admin/settings/users/:id',
                component: UpdateUser,
            },
            {
                path: '/admin/settings/my-user',
                component: UpdateUser,
            },
            {
                path: '/sync',
                component: Synchronization,
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
