<template>
    <v-container fluid>
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-account-multiple</v-icon> <span>Sincronização</span>
        </h1>
        <div style="position:relative" v-if="!loading">
            <v-card>
                <v-container fluid>
                    <v-row class="ma-2 pa-2">
                        <v-col cols="6">
                            <v-text-field
                                v-model="fields.external_database"
                                label="Database name"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="fields.external_host"
                                label="Database host"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="fields.username"
                                label="Username"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="fields.password"
                                label="Password"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                :items="tenants"
                                v-model="fields.tenant"
                                label="Tenant"
                                item-value="id"
                                item-text="id"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                :items="tables"
                                v-model="fields.table"
                                label="Tabela"
                                @change="getFields"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="fields.external_table"
                                label="Tabela externa"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="fields.db_fields != null">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Reportlake Fields
                                        </th>
                                        <th class="text-left">
                                            External Fields
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in fields.db_fields"
                                            :key="item.reportlake"
                                        >
                                            <td>{{ item.reportlake }}</td>
                                            <td>
                                                <v-text-field v-model="item.external"
                                                            label="Nome campo externo"></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12">
                            <v-btn color="success" @click="startImport">Extrair</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </div>


    </v-container>
</template>
<script>
import Tenant from "@/api/Tenant.js";
import Synchronization from "@/api/Synchronization.js";

export default{
    name: 'Synchronization',
    data(){
        return{
            loading: true,
            tables:['materials', 'workers', 'machines'],
            tenants:[],
            fields:{
                database: '',
                username: '',
                password: '',
                tenant: null,
                table: '',
                external_table: null,
                db_fields: null
            }
        }
    },
    async mounted(){
        await this.fillBaseData()
        this.loading = false
    },
    methods:{
        startImport(){
            Synchronization.import(this.fields)
                .then(()    =>  {

                })
        },
        async fillBaseData(){
            await Tenant.list()
            .then((data) => {
                this.tenants = data.data
            }).catch(() => {

            })
        },
        getFields(){
            Synchronization.getFields(this.fields.table)
                .then((data)    =>  {

                    this.fields.db_fields = data.data.map((item) => { 
                        return {
                            reportlake: item,
                            external: null
                        }
                    });
                })
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Sincronização',
            disabled: false,
            to: '/sync',
            exact: true,
            },
        ];
        }
    }
}
</script>