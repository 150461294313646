<template>
    <v-container>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="900px"
        >
            
            <v-card v-if="!this.loading">
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>                        
                        <span class="text-h5">Subscrição</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">      
                        <v-form class="mb-16" @submit.prevent="addSubscription">
                            <fieldset :disabled="loading">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <validation-provider v-slot="{ errors }" vid="Inicio" name="Inicio" rules="required">
                                                    <v-text-field
                                                        v-model="fields.subscription_start"
                                                        label="Início"
                                                        prepend-icon="mdi-calendar"
                                                        :error-messages="errors"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.subscription_start"
                                            @input="menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" vid="Tipo" name="Tipo" rules="required">
                                            <v-select
                                                :items="subscription_types"
                                                :error-messages="errors"
                                                label="Subscrição"
                                                item-text="type"
                                                item-value="id"
                                                v-model="fields.subscription_id"
                                            ></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <validation-provider v-slot="{ errors }" vid="Fim" name="Fim" rules="required">
                                                    <v-text-field
                                                        v-model="fields.subscription_end"
                                                        label="Fim"
                                                        prepend-icon="mdi-calendar"
                                                        :error-messages="errors"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.subscription_end"
                                            @input="menu2 = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-checkbox
                                            v-model="fields.addInvoice"
                                            label="Associar fatura"
                                        />
                                    </v-col>
                                    <v-row v-if="fields.addInvoice">
                                        <v-col cols="12" md="8">
                                            <v-file-input
                                                accept=".pdf"
                                                label="Fatura"
                                                v-model="fields.invoice.file"
                                            ></v-file-input>
                                        </v-col>                                        
                                        <v-col cols="12" md="4">
                                            <v-checkbox
                                                v-model="fields.invoice.paid"
                                                label="Paga"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12">                                            
                                            <validation-provider v-slot="{ errors }" vid="title" name="title" rules="required">
                                                <v-text-field :error-messages="errors" label="Titulo" v-model="fields.invoice.title"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                                <v-textarea
                                                name="input-7-1"
                                                v-model="fields.invoice.description"
                                                :error-messages="errors"
                                                label="Descriçao"
                                                ></v-textarea>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="6">                                            
                                            <validation-provider v-slot="{ errors }" vid="total" name="total" rules="required">
                                                <v-text-field :error-messages="errors" label="Total" v-model="fields.invoice.total" prepend-icon="mdi-currency-eur"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="6">                                            
                                            <v-menu
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <validation-provider v-slot="{ errors }" vid="Expira" name="Expira" rules="required">
                                                        <v-text-field
                                                            v-model="fields.invoice.expires"
                                                            label="Expira"
                                                            prepend-icon="mdi-calendar"
                                                            :error-messages="errors"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </template>
                                                <v-date-picker
                                                v-model="fields.invoice.expires"
                                                @input="menu3 = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-row>   
                                <v-row class="mt-6" align="center" justify="space-around">
                                    <v-col align="center" justify="space-around">
                                        <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                            Renovar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </fieldset>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import Subscription from "@/api/Subscription.js";

export default {
    components:{
        
    },
    async mounted(){
        this.loading = false
    },
    props:{
        subscription_types: Array,
        method: {
            typeof: String,
            default: 'create'
        },
        tenant: String
    },
    data(){
        return{
            menu: false,
            menu2: false,
            menu3: false,
            fields:{
                subscription_start: null,
                subscription_end: null,
                subscription_id: null,
                invoice: {
                    paid: false,
                    object: null,
                    description: '',
                    total: '',
                    expires: null,
                    file: null,
                    id: null,
                    title: ''
                },
                addInvoice: false
            },
            loading: false, 
            dialog: false,
            label: 'Subscrição'
        }
    },
    methods:{
        addSubscription(){
            const formData = new FormData()
            formData.append('subscription_start', this.fields.subscription_start)
            formData.append('subscription_end', this.fields.subscription_end)
            formData.append('subscription_id', this.fields.subscription_id)
            formData.append('addInvoice', this.fields.addInvoice)

            formData.append('file', this.fields.invoice.file)
            formData.append('tenant', this.tenant)
            formData.append('invoice', JSON.stringify(this.fields.invoice))
            formData.append('id', this.fields.invoice.id)


            
            Subscription[this.method](formData, this.tenant).then(() => {

                this.success = true;

                this.loading = false;

            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        openDialog(){
            this.dialog = true
        },
        subStartChanged(dt){
            this.fields.subscription_start = dt
        },
        subEndChanged(dt){
            this.fields.subscription_end = dt
        },
    }
}
</script>