import BaseApi from "./BaseApi";

export default class Tenant extends BaseApi{
    construct() {}

    static async search(params) {
        return await Tenant.getAxiosInstance().get(Tenant.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Tenant.getAxiosInstance().post(Tenant.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Tenant.getAxiosInstance().put(Tenant.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Tenant.getAxiosInstance().delete(Tenant.getApiUrl() + id);
    }

    static async find(id) {
        return await Tenant.getAxiosInstance().get(Tenant.getApiUrl() + id);
    }

    static async list(params) {
        let url = Tenant.getApiUrl() + "list";

        return Tenant.getAxiosInstance().get(url,{params: params});
    }

    static async addFeature(params){
        let url = Tenant.getApiUrl() + "features/add";

        return Tenant.getAxiosInstance().post(url, params);
    }

    static async removeFeature(params){
        let url = Tenant.getApiUrl() + "features/delete";

        return Tenant.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/tenants/";
    }

}