import BaseApi from "./BaseApi";

export default class Configurations extends BaseApi{
    construct() {}

    static async update(tenant, params, id) {
        return await Configurations.getAxiosInstance().put(Configurations.getFullApiUrl(tenant) + id,params);
    }

    static async get(tenant) {
        return await Configurations.getAxiosInstance().get(Configurations.getFullApiUrl(tenant));
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/configurations/";
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/configurations/";
    }
}