import BaseApi from "./BaseApi";

export default class SubscriptionHistory extends BaseApi{
    construct() {}

    static async list() {
        let url = SubscriptionHistory.getApiUrl() + "list";

        return SubscriptionHistory.getAxiosInstance().get(url);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/subscriptions-history/";
    }

}