<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-account-multiple</v-icon> <span>Registo de Subscrições</span>
        </h1>
  
        <div class="mt-16">
          <subscription method="create"></subscription>
        </div>
    </section>
  </template>
  <script>
  import subscription from '@/components/subscriptions_types/SubscriptionType'
  
  export default {
    components: {
        subscription
    },
    beforeCreate(){
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "subscriptions.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Subscrições',
            disabled: false,
            to: '/subscriptions_types',
            exact: true,
          },
          {
            text: 'Registo de Subscrição',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  