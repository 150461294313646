<template>
    <v-card
        class="mx-auto elevation-4"
        max-width="344"
        >
        <v-list-item three-line>
            <v-list-item-content>
            <div class="text-overline mb-4">
                {{ title }}
            </div>
            <v-list-item-title class="text-h5 mb-1">
                {{ subTitle }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                :color="avatarColor"
            ><v-icon :color="iconColor">{{ icon }}</v-icon></v-list-item-avatar>

        </v-list-item>
    </v-card>
</template>
<script>
export default{
    props:{
        title: String,
        subTitle: null,
        description: String,
        icon: String,
        avatarColor: {
            typeof: String,
            default: "primary"
        },
        iconColor:{
            typeof: String,
            default: "white"
        }
    }
}
</script>