<template>
    <section class="mt-16">
      
      <FeaturesComponent />
      </section>
</template>
<script>
    
import FeaturesComponent from '@/components/features/FeaturesComponent.vue';

export default{
    components: {
        FeaturesComponent
    }
}
</script>