<template>
    <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <Card title="Users" :subTitle="data.qty_users" description="registados" icon="mdi-account-multiple"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Online" :subTitle="getUsersOnline(data.online)" description="users" icon="mdi-account-badge-outline"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Obras" :subTitle="data.tenants.worksCounter" description="total" icon="mdi-dump-truck"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Diárias" :subTitle="data.tenants.diariesCounter" description="total" icon="mdi-notebook"/>
          </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Card from '@/components/ui/Card.vue';

export default{
  components:{
    Card,
  },
  props: ['data'],
  methods:{
    getUsersOnline(users){      
      return users.length
    }
  }
}

</script>