<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="900px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>                        
                        <span class="text-h5" v-if="method=='create'">Registar fatura</span>
                        <span class="text-h5" v-else>Editar fatura</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">      
                    <v-form class="mb-16" @submit.prevent="addInvoice">
                        <fieldset :disabled="loading">
                        <v-row>
                            <v-col cols="12" md="8">
                            <validation-provider v-if="method=='create'" v-slot="{ errors }" vid="file" name="file" rules="required">
                                <v-file-input
                                    accept=".pdf"
                                    label="Fatura"
                                    :error-messages="errors"
                                    v-model="invoice.file"
                                ></v-file-input>
                            </validation-provider>
                            <v-btn
                                v-else
                                color="blue-grey"
                                class="ma-2 white--text"
                                @click="downloadInvoice"
                                >
                                Download fatura
                                <v-icon
                                    right
                                    dark
                                >
                                    mdi-download-box
                                </v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="invoice.paid"
                                label="Paga"
                            ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <validation-provider v-slot="{ errors }" vid="title" name="title" rules="required">
                                    <v-text-field :error-messages="errors" label="Titulo" v-model="invoice.title"></v-text-field>
                                </validation-provider>
                            </v-col>     
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="invoice.description"
                                :error-messages="errors"
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                            <validation-provider v-slot="{ errors }" vid="total" name="total" rules="required">
                                <v-text-field :error-messages="errors" label="Total" v-model="invoice.total" prepend-icon="mdi-currency-eur"></v-text-field>
                            </validation-provider>
                            </v-col>
                            <v-col cols="6">
                            <validation-provider v-slot="{ errors }" vid="expires_at" name="expires_at" rules="required">
                                <DatePicker :error-messages="errors" label="Expira a" @changed="expireChanged" :date="invoice.expires_at" return-object/>
                            </validation-provider>
                            </v-col>
                        </v-row>   
                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                        </v-row>
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Fatura gravada com sucesso
        </DialogSuccess>
    </v-row>
</template>
<script>
import DatePicker from '@/components/ui/DatePicker'
import Invoice from "@/api/Invoice.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
    components:{
        DatePicker,
        DialogSuccess,
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'create'
        },
        selectedInvoice:{
            default: null
        }
    },
    watch:{
        selectedInvoice:{
            immediate: true,
            handler(val){
                if(val == null)
                    return
                
                val.expires_at = val.expires_at.split(' ')[0]
                this.invoice.description = val.description
                this.invoice.total = val.total
                this.invoice.expires_at = val.expires_at
                this.invoice.paid = val.paid
                this.invoice.file = val.file
                this.invoice.path = val.path
                this.invoice.id = val.id
                this.invoice.title = val.title
            }
        }
    },
    data(){
        return{
            dialog: false,
            loading: false,         
            success: false, 
            invoice:{
                file: null,
                total: null,
                paid: false,
                description: '',
                expires_at: '',
                id: '',
                title: '',
            }
        }
    },
    async mounted(){
        this.loading = true
        this.invoice.expires_at = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        
        this.loading = false
    },
    methods:{
        resetField(){
            this.invoice = {
                file: null,
                total: null,
                paid: false,
                description: '',
                expires_at: '',
                id: '',
                title: '',
            }
        },
        downloadInvoice(){
            Invoice['download'](this.invoice.id, this.tenant).then((response) => {
                let documentName =  this.invoice.title

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        expireChanged(dt){
            this.invoice.expires_at = dt
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadInvoices')
        },
        openDialog(){
            this.dialog = true
        },
        addInvoice(){
            const formData = new FormData()
            formData.append('file', this.invoice.file)
            formData.append('invoice', JSON.stringify(this.invoice))
            formData.append('id', this.invoice.id)
            
            
            Invoice[this.method](formData, this.tenant).then(() => {
        
                this.success = true;

                this.loading = false;

                this.resetField()

            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
    }
}
</script>